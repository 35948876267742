import router from "./index";
import store from "@/store";
import { Message } from "element-ui";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login", "/404"];

router.beforeEach((to, from, next) => {
  localStorage.setItem("Routing_jump_in_progress", "1");
  NProgress.start();
  const token = store.state.token;
  const role = store.state.userInfo ? store.state.userInfo.user_role : "";
  if (whiteList.includes(to.path)) {
    // console.log(from.path);
    next();
  } else {
    if (token && store.state.userInfo) {
      // 已登录
      if (to.meta.role.includes(role)) {
        next();
      } else {
        // 判断是否为普通警员且是否从首页过来
        console.log(role === 0);
        if (role === 0 && from.path === "/") {
          next("/task-list-police");
          return;
        }
        next(false);
        localStorage.removeItem("Routing_jump_in_progress");
        NProgress.done();
        Message({
          message: "暂无该页面权限",
          type: "error",
          duration: 2000,
        });
        return;
      }
    } else {
      // 未登录
      next("/login");
      localStorage.removeItem("Routing_jump_in_progress");
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  localStorage.removeItem("Routing_jump_in_progress");
  NProgress.done();
});
