import Vue from "vue";
import Vuex from "vuex";
// import { getUserInfo } from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("P-C-Token") || "",
    userInfo: JSON.parse(localStorage.getItem("P-C-UserInfo") || "null"),
    select_pcs_id: JSON.parse(localStorage.getItem("P-C-PcsId")) || "",
  },
  getters: {
    pcs_id(state) {
      return state.userInfo?.pcs_id || '';
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("P-C-Token", token);
    },
    removeToken(state) {
      state.token = "";
      localStorage.removeItem("P-C-Token");
    },
    setUserInfo(state, info) {
      state.userInfo = info;
      localStorage.setItem("P-C-UserInfo", JSON.stringify(info));
    },
    removeUserInfo(state) {
      state.userInfo = null;
      localStorage.removeItem("P-C-UserInfo");
    },
    setPcsId(state, id) {
      // console.log(info);
      state.select_pcs_id = id;
      localStorage.setItem("P-C-PcsId", JSON.stringify(id));
    },
    removePcsId(state) {
      state.select_pcs_id = '';
      localStorage.removeItem("P-C-PcsId");
    }
  },
  actions: {
    // async getUserInfo(content) {
    //   const data = await getUserInfo(content.state.userId);
    //   content.commit("setUserInfo", data);
    //   return data;
    // },
  },
  modules: {},
});
