import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
  },
  {
    path: "/",
    name: "layout",
    redirect: "/home",
    component: () => import("@/views/layout"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home"),
        meta: {
          title: "首页",
          role: [1, 2],
        },
      },
      {
        path: "/organizational",
        name: "organizational",
        component: () => import("@/views/user-management/Organizational"),
        meta: {
          title: "用户管理/组织机构管理",
          role: [1],
        },
      },
      {
        path: "/department",
        name: "department",
        component: () => import("@/views/user-management/Department"),
        meta: {
          title: "用户管理/部门管理",
          role: [1],
        },
      },
      {
        path: "/policeman",
        name: "policeman",
        component: () => import("@/views/user-management/Policeman"),
        meta: {
          title: "用户管理/直属民警管理",
          role: [1],
        },
      },
      {
        path: "/taskdetails",
        name: "taskdetails",
        component: () => import("@/views/user-management/TaskDetails"),
        meta: {
          title: "用户管理/任务详情",
          role: [1, 2],
        },
      },
      // {
      //   path: "/role",
      //   name: "role",
      //   component: () => import("../views/permission/Role"),
      //   meta: {
      //     title: "权限管理/角色管理",
      //   },
      // },
      // {
      //   path: "/administrator",
      //   name: "administrator",
      //   component: () => import("../views/permission/Administrator"),
      //   meta: {
      //     title: "权限管理/直属派出所管理员管理",
      //   },
      // },
      // {
      //   path: "/basic-data",
      //   name: "basic-data",
      //   component: () => import("../views/data-management/BasicData"),
      //   meta: {
      //     title: "一警十联数据管理/基础数据绑定管理",
      //   },
      // },
      {
        path: "/street-and-town-leaders",
        name: "street-and-town-leaders",
        component: () =>
          import("../views/data-management/StreetAndTownLeaders"),
        meta: {
          title: "一警十联数据管理/街镇领导管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/npc-deputies",
        name: "npc-deputies",
        component: () => import("../views/data-management/NPCDeputies"),
        meta: {
          title: "一警十联数据管理/人大代表政协委员管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/village-leaders",
        name: "village-leaders",
        component: () => import("../views/data-management/VillageLeaders"),
        meta: {
          title: "一警十联数据管理/村居领导管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/mass-supervisor",
        name: "mass-supervisor",
        component: () => import("../views/data-management/MassSupervisor"),
        meta: {
          title: "一警十联数据管理/群众监督员管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/key-personnel",
        name: "key-personnel",
        component: () => import("../views/data-management/KeyPersonnel"),
        meta: {
          title: "一警十联数据管理/重点、关注人员管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/poor-people",
        name: "poor-people",
        component: () => import("../views/data-management/PoorPeople"),
        meta: {
          title: "一警十联数据管理/困难群众管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/four-losses-and-five-categories",
        name: "four-losses-and-five-categories",
        component: () =>
          import("../views/data-management/FourLossesAndFiveCategories"),
        meta: {
          title: "一警十联数据管理/四失五类人员管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/village-residents",
        name: "village-residents",
        component: () => import("../views/data-management/VillageResidents"),
        meta: {
          title: "一警十联数据管理/村居居民管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/enterprise-merchants",
        name: "enterprise-merchants",
        component: () => import("../views/data-management/EnterpriseMerchants"),
        meta: {
          title: "一警十联数据管理/企业商户管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/schools-and-kindergartens",
        name: "schools-and-kindergartens",
        component: () =>
          import("../views/data-management/SchoolsAndKindergartens"),
        meta: {
          title: "一警十联数据管理/学校、幼儿园管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/workforce-management",
        name: "workforce-management",
        component: () =>
          import("../views/workforce-management/WorkforceManagement"),
        meta: {
          title: "在岗人数管理/在岗人数",
          role: [2],
        },
      },
      {
        path: "/interview-public-opinion",
        name: "interview-public-opinion",
        component: () =>
          import("../views/three-visits-and-one-report/InterviewPublicOpinion"),
        meta: {
          title: "三访一汇报记录管理/访民意记录管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/interview-public-security",
        name: "interview-public-security",
        component: () =>
          import(
            "../views/three-visits-and-one-report/InterviewPublicSecurity"
          ),
        meta: {
          title: "三访一汇报记录管理/访治安状况管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/interview-party",
        name: "interview-party",
        component: () =>
          import("../views/three-visits-and-one-report/InterviewParty"),
        meta: {
          title: "三访一汇报记录管理/访案件当事人管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/report-to-public",
        name: "report-to-public",
        component: () =>
          import("../views/three-visits-and-one-report/ReportToPublic"),
        meta: {
          title: "三访一汇报记录管理/向群众汇报管理",
          role: [0, 1, 2],
        },
      },
      {
        path: "/task-list-suboffice",
        name: "task-list-suboffice",
        component: () => import("../views/task-management/TaskListSuboffice"),
        meta: {
          title: "任务管理/任务列表-分局",
          role: [1],
        },
      },
      //派出所自建任务列表
      {
        path: "/task-list-custom",
        name: "task-list-custom",
        component: () => import("../views/task-management/TaskListCustom"),
        meta: {
          title: "任务管理/任务列表-派出所",
          role: [2],
        },
      },
      //分局下发派出所任务列表
      {
        path: "/task-list-precinct",
        name: "task-list-precinct",
        component: () => import("../views/task-management/TaskListPrecinct"),
        meta: {
          title: "任务管理/任务列表-派出所",
          role: [1, 2],
        },
      },
      {
        path: "/task-list-police",
        name: "task-list-police",
        component: () => import("../views/task-management/TaskListPolice"),
        meta: {
          title: "任务管理/任务列表-个人",
          role: [0, 2],
        },
      },
      {
        path: "/task-detail",
        name: "task-detail",
        component: () => import("../views/task-management/TaskDetail"),
        meta: {
          title: "任务管理/任务详情",
          role: [0, 1, 2],
        },
      },
      {
        path: "/timed-task-list",
        name: "timed-task-list",
        component: () => import("../views/scheduled-task-management/TaskList"),
        meta: {
          title: "定时任务管理/定时任务列表",
          role: [0, 1, 2],
        },
      },
      /*   {
          path: "/task-push-record",
          name: "task-push-record",
          component: () => import("../views/scheduled-task-management/TaskPushRecord"),
          meta: {
            title: "定时任务管理/任务推送记录",
            role: [1, 2],
          },
        }, */
      {
        path: "/visiting-data",
        name: "visiting-data",
        component: () =>
          import("../views/data-statistics-management/VisitingData"),
        meta: {
          title: "走访数据统计管理/走访数据统计",
          role: [1, 2],
        },
      },
      {
        path: "/visiting-public-security-data",
        name: "visiting-public-security-data",
        component: () =>
          import(
            "../views/data-statistics-management/VisitingPublicSecurityData.vue"
          ),
        meta: {
          title: "走访数据统计管理/访治安数据统计",
          role: [1, 2],
        },
      },
      //分局大屏
      {
        path: "/data-big-screen-suboffice",
        name: "data-big-screen-suboffice",
        component: () =>
          import(
            "../views/data-statistics-management/data-big-screen-suboffice"
          ),
        meta: {
          title: "走访数据统计管理/走访数据可视化管理",
          role: [1],
          select: true,
        },
      },
      //派出所大屏
      {
        path: "/data-big-screen-precinct",
        name: "data-big-screen-precinct",
        component: () =>
          import(
            "../views/data-statistics-management/data-big-screen-precinct"
          ),
        meta: {
          title: "走访数据统计管理/走访数据可视化管理",
          role: [1, 2],
          select: true,
        },
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../404.vue"),
  },
  {
    path: "*",
    redirect: {
      name: "404",
    },
  },
];

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes,
  });

const router = createRouter();

export default router;
