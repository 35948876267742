// 封装 rem + js 自适应方案
export function autoSize() {
  //设计稿的宽度
  var psdWidth = 1920;

  //预设字体值
  var preFontSize = 1;

  //当前屏幕宽度
  var curScreenWidth = document.documentElement.clientWidth;

  if (curScreenWidth < 1080) {
    curScreenWidth = 1080;
  }
  //当前屏幕对应的html字体值
  var curFontSize = (curScreenWidth * preFontSize) / psdWidth;

  //把计算下来的当前屏幕html字体值 赋值给html元素 documentElement属性会获取到html根元素 然后为之设置字体值
  document.documentElement.style.fontSize = curFontSize + "px";
}
