<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { autoSize } from "./utils/autoSize";
export default {
  mounted() {
    autoSize();
    window.addEventListener("resize", () => {
      autoSize();
    });
  },
};
</script>

<style lang="scss"></style>
