import Vue from "vue";
import { Button } from "element-ui";
import { Select } from "element-ui";
import { Option } from "element-ui";
import { Checkbox } from "element-ui";
import { Input } from "element-ui";
import { Form } from "element-ui";
import { FormItem } from "element-ui";
import { Table } from "element-ui";
import { TableColumn } from "element-ui";
import { Dialog } from "element-ui";
import { Pagination } from "element-ui";
import { Upload } from "element-ui";
import { Progress } from "element-ui";
import { Message } from "element-ui";
import { Dropdown } from "element-ui";
import { DropdownMenu } from "element-ui";
import { DropdownItem } from "element-ui";
import { MessageBox } from "element-ui";
import { Submenu } from "element-ui";
import { Menu } from "element-ui";
import { MenuItem } from "element-ui";
import { MenuItemGroup } from "element-ui";
import { RadioGroup } from "element-ui";
import { DatePicker } from "element-ui";
import { Scrollbar } from "element-ui";
import { Steps } from "element-ui";
import { Step } from "element-ui";
import { Tooltip } from "element-ui";
import { Card } from "element-ui";
import { Divider } from "element-ui";
import { Cascader } from "element-ui";
import { Popover } from "element-ui";
import { Link } from "element-ui";
import { Col } from "element-ui";
import { Loading } from "element-ui";
import { Empty } from "element-ui";

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(RadioGroup);
Vue.use(Menu);
Vue.use(DatePicker);
Vue.use(Scrollbar);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Tooltip);
Vue.use(Card);
Vue.use(Divider);
Vue.use(Cascader);
Vue.use(Popover);
Vue.use(Link);
Vue.use(Col);
Vue.use(Loading);
Vue.use(Empty);
