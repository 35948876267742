import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import vueSeamlessScroll from "vue-seamless-scroll";
// 引入svg-icon
import "@/icons";
import * as echarts from "echarts";
import "@/utils/element-ui.js";
import "@/router/permission";
import "@/style/common.scss";
// vue中导出excel表格模板
import FileSaver from 'file-saver';
import XLSX from 'xlsx';


// 将excel表格模板设置全局
Vue.prototype.$FileSaver = FileSaver;
Vue.prototype.$XLSX = XLSX;
Vue.prototype.$echarts = echarts;
Vue.use(vueSeamlessScroll);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
